exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-study-[ids]-js": () => import("./../../../src/pages/case-study/[ids].js" /* webpackChunkName: "component---src-pages-case-study-[ids]-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customer-reviews-js": () => import("./../../../src/pages/customer-reviews.js" /* webpackChunkName: "component---src-pages-customer-reviews-js" */),
  "component---src-pages-framework-js": () => import("./../../../src/pages/framework.js" /* webpackChunkName: "component---src-pages-framework-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-general-services-js": () => import("./../../../src/pages/industries/general-Services.js" /* webpackChunkName: "component---src-pages-industries-general-services-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-legal-js": () => import("./../../../src/pages/industries/legal.js" /* webpackChunkName: "component---src-pages-industries-legal-js" */),
  "component---src-pages-industries-manufacturing-js": () => import("./../../../src/pages/industries/manufacturing.js" /* webpackChunkName: "component---src-pages-industries-manufacturing-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-condition-js": () => import("./../../../src/pages/legal/terms-and-condition.js" /* webpackChunkName: "component---src-pages-legal-terms-and-condition-js" */),
  "component---src-pages-location-[ids]-js": () => import("./../../../src/pages/location/[ids].js" /* webpackChunkName: "component---src-pages-location-[ids]-js" */),
  "component---src-pages-location-index-js": () => import("./../../../src/pages/location/index.js" /* webpackChunkName: "component---src-pages-location-index-js" */),
  "component---src-pages-multiple-index-js": () => import("./../../../src/pages/Multiple/index.js" /* webpackChunkName: "component---src-pages-multiple-index-js" */),
  "component---src-pages-portfolio-[portfolio-inner]-index-js": () => import("./../../../src/pages/portfolio/[portfolio-inner]/index.js" /* webpackChunkName: "component---src-pages-portfolio-[portfolio-inner]-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-response-js": () => import("./../../../src/pages/response.js" /* webpackChunkName: "component---src-pages-response-js" */),
  "component---src-pages-services-customer-growth-as-service-content-marketing-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/content-marketing-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-content-marketing-services-js" */),
  "component---src-pages-services-customer-growth-as-service-ecommerce-solution-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/ecommerce-solution-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-ecommerce-solution-services-js" */),
  "component---src-pages-services-customer-growth-as-service-index-js": () => import("./../../../src/pages/services/customer-growth-as-service/index.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-index-js" */),
  "component---src-pages-services-customer-growth-as-service-paid-marketing-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/paid-marketing-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-paid-marketing-services-js" */),
  "component---src-pages-services-customer-growth-as-service-seo-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/seo-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-seo-services-js" */),
  "component---src-pages-services-customer-growth-as-service-web-development-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/web-development-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-web-development-services-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-innovation-as-service-bpm-and-okr-services-js": () => import("./../../../src/pages/services/innovation-as-service/bpm-and-okr-services.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-bpm-and-okr-services-js" */),
  "component---src-pages-services-innovation-as-service-cloud-services-js": () => import("./../../../src/pages/services/innovation-as-service/cloud-services.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-cloud-services-js" */),
  "component---src-pages-services-innovation-as-service-cybersecurity-services-js": () => import("./../../../src/pages/services/innovation-as-service/cybersecurity-services.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-cybersecurity-services-js" */),
  "component---src-pages-services-innovation-as-service-digital-transformation-strategy-js": () => import("./../../../src/pages/services/innovation-as-service/digital-transformation-strategy.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-digital-transformation-strategy-js" */),
  "component---src-pages-services-innovation-as-service-index-js": () => import("./../../../src/pages/services/innovation-as-service/index.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-index-js" */),
  "component---src-pages-services-innovation-as-service-managed-it-service-js": () => import("./../../../src/pages/services/innovation-as-service/managed-it-service.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-managed-it-service-js" */),
  "component---src-pages-services-innovation-as-service-process-optimization-services-js": () => import("./../../../src/pages/services/innovation-as-service/process-optimization-services.js" /* webpackChunkName: "component---src-pages-services-innovation-as-service-process-optimization-services-js" */)
}

